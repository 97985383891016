<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :title="t('dap.title')"
                :subtitle="t('dap.simulateInvest')"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyInfo
                    :data="balanceInfo"
                    opacity="high"
                    :inverse="inverse"
                />
                <OrqColumn :gap="24">
                    <OrqContentSwitcher
                        :options="options"
                        :inverse="inverse"
                        @selected="onSelectedCurrency"
                    />
                    <BodyInvestAmount
                        v-model.number="inputAmount"
                        currency="CLP"
                        :description="investError ?? investDesc"
                        :error="!!investError"
                        :error-msg="investError ?? null"
                        hide-button
                        placeholder="$0"
                        :inverse="inverse"
                        @update:model-value="debouncedTouch()"
                    />
                    <div class="input-container">
                        <OrqInput
                            v-model.number="order.term"
                            size="large"
                            :label="hideLabel ? null : t('dap.term')"
                            :description="termError ?? termDesc"
                            :error="!!termError"
                            :error-msg="termError ?? null"
                            :inverse="inverse"
                            placeholder="0"
                            @update:model-value="v$.term.$touch"
                        />
                        <span
                            class="input-days heading-lg"
                            :class="`text--emphasis-base-low${
                                inverse ? '-inverse' : ''
                            }`"
                            >{{ t('dap.day', order.term) }}</span
                        >
                    </div>
                </OrqColumn>
            </OrqColumn>
        </template>

        <template #footer>
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid && !loading"
                :next-label="t('dap.simulate')"
                :inverse="inverse"
                :loading="loading"
                :icon="loading ? 'loading' : ''"
                @next="onNext()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import dapService from '@/_services/dap.service';

import { ref, computed, onMounted } from 'vue';
import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from '../TransactionalFooter.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import BodyInvestAmount from '../body-components/BodyInvestAmount.vue';
import { OrqInput, OrqDrawerBody, OrqColumn } from '@digital/orquestra';
import { OrqContentSwitcher } from '@orquestra-web/vue';
import { OPERATION } from '@/consts';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { nonZero, minValue, valueIn } from '@/_helpers/rules.helper.js';
import { formatCurrency, removeFormat } from '@/directives/currencyFormat';
import { formatDate } from '@/utils/text.js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import { debounce } from 'lodash';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps } = storeToRefs(orderStore);
const { initOrder, nextStep } = orderStore;

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

import { useDapStore } from '@/_store/dap.store.js';
const dapStore = useDapStore();
const { days, ufDate } = storeToRefs(dapStore);

const { t } = useI18n();
const inputAmount = ref(null);

const balanceInfo = computed(() => {
    return [
        {
            title: t('moneda.availableBalance'),
            value: {
                amount: balance.value.CLP,
                format: 'CLP',
            },
        },
    ];
});

const options = ref([
    {
        label: t('dap.inCLP'),
        value: 'CLP',
        selected: true,
        disabled: false,
        data: {
            id: 1,
        },
    },
    {
        label: t('dap.inUF'),
        value: 'UF',
        selected: false,
        disabled: false,
        data: {
            id: 2,
        },
    },
]);

function onSelectedCurrency(evt, selected) {
    if (selected.value != order.value.currency) {
        order.value.amount = 0;
        order.value.term = days.value[selected.value][0];
        v$.value.$reset();
    }
    options.value.forEach((item) => {
        if (item.data.id === selected.data.id) {
            item.selected = true;
            order.value.currency = item.value;
        } else {
            item.selected = false;
        }
    });
}
onMounted(() => {
    dapStore.getUFDate();
    options.value.forEach((item) => {
        if (item.value === props.currency) {
            item.selected = true;
            order.value.currency = item.value;
        } else {
            item.selected = false;
        }
    });
});

const investDesc = computed(
    () => `${t('dap.minAmount')} ${formatCurrency(50000, 'CLP')}`
);

const expDate = computed(() => {    
    const now = dayjs(ufDate.value, 'DD/MM/YYYY');
    return now.add(order.value.term, 'day');
});
const termDesc = computed(
    () => `${t('dap.expiration')} ${formatDate(expDate.value, 'dayOfWeek')}`
);

const debouncedTouch = debounce(() => {
    v$.value.$touch();
    order.value.amount = removeFormat(inputAmount.value);
}, 200);

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    term: {
        type: Number,
        default: 7,
    },
    currency: {
        type: String,
        default: 'CLP',
    },
});

const rules = computed(() => {
    return {
        amount: {
            moreThanMin: minValue(50000),
            nonZero: nonZero(),
        },
        term: {
            available: valueIn(days.value[order.value.currency]),
        },
    };
});

const v$ = useVuelidate(rules, order);

const investError = computed(
    () => v$.value.amount.$errors[0]?.$message ?? null
);
const termError = computed(() =>
    v$.value.term.$errors.length > 0
        ? `${termDesc.value}. ${v$.value.term.$errors[0]?.$message}`
        : null
);

const isValid = computed(
    () => v$.value.$dirty && v$.value.$errors.length === 0
);

const loading = ref(false);
const onNext = async () => {
    loading.value = true;
    dapService
        .simulation(order.value)
        .then((result) => {
            order.value.simulationList = result.data;
            order.value.simulation = result.data[0];
        })
        .finally(() => {
            loading.value = false;
            nextStep();
        });
};

initOrder({
    idEncoded: props.item?.idEncoded,
    amount: 0,
    currency: props.currency,
    term: props.term,
    operation: OPERATION.BUY,
});
</script>

<style lang="scss" scoped>
.switch-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.switch-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
.input {
    &-container {
        position: relative;
        width: 100%;
    }
    &-days {
        position: absolute;
        right: 0;
        top: 25px;
    }
}
</style>
